import React from "react";
import {CardActions, CreateButton, Datagrid, Filter, List, RefreshButton, TextField, TextInput} from "react-admin";

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn/>
    </Filter>
);

const CustomActions = () => <CardActions>
    <CreateButton/>
    <RefreshButton/>
</CardActions>;

const UserList = props => {
    return (
        <List bulkActionButtons={false} {...props} filters={<UserFilter/>} actions={<CustomActions/>}>
            <Datagrid rowClick="show">
                <TextField source="entity_id" label="ID"/>
                <TextField source="email" label="Email"/>
                <TextField source="nome" label="nome"/>
            </Datagrid>
        </List>
    );
};

export default UserList;
