import { API } from "aws-amplify";

import config from "../config/constants";

export const getUrl = () => {
  return API.get(
    config.APIS.LEGALPHARMA_ADMIN,
    "import_excel/presigned_post"
  ).then((res) => {
    return res.data;
  });
};

export const getUrDocumentlUpload = () => {
  return API.get(config.APIS.LEGALPHARMA_ADMIN, "admin/documento/upload").then(
    (res) => {
      return res;
    }
  );
};

export const getUrRelatedDocumentlUpload = () => {
  return API.get(
    config.APIS.LEGALPHARMA_ADMIN,
    "admin/documento/upload/relacionado"
  ).then((res) => {
    return res;
  });
};
