const APIS = {
  LEGALPHARMA: "legalpharma-api",
  LEGALPHARMA_PUBLIC: "legalpharma-public-api",
  LEGALPHARMA_ADMIN: "legalpharma-admin-api",
};

const STAGES = {
  dev: {
    COGNITO: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_BPCFOzuVv",
      APP_CLIENT_ID: "27g3ocae5f6tjtadt79rapbit6",
    },
    APIS,
    DOMAIN: "https://k9jzcca0z7.execute-api.us-east-1.amazonaws.com/dev/",
    DOMAIN_ADMIN: "https://k9jzcca0z7.execute-api.us-east-1.amazonaws.com/dev/",
  },
  stg: {
    COGNITO: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_BPCFOzuVv",
      APP_CLIENT_ID: "27g3ocae5f6tjtadt79rapbit6",
    },
    APIS,
    DOMAIN: " https://w61ggcs451.execute-api.us-east-1.amazonaws.com/stg/",
    DOMAIN_ADMIN: " https://w61ggcs451.execute-api.us-east-1.amazonaws.com/stg/",
  },
  prd: {
    COGNITO: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_LhvEZOnd0",
      APP_CLIENT_ID: "7ndf22ns7ie0gi2vgmp0otbro7",
    },
    APIS,
    DOMAIN: " https://t67w8mvtr1.execute-api.us-east-1.amazonaws.com/prd/",
    DOMAIN_ADMIN: " https://t67w8mvtr1.execute-api.us-east-1.amazonaws.com/prd/",
  },
};

const env = process.env.REACT_APP_STAGE || "dev";
const config = STAGES[env];

export default config;
