import React from "react";
import "./App.scss";
import { Admin, Resource } from "react-admin";
import dataProvider from "./services/data-provider";
import authProvider from "./services/auth-provider";

import DocumentEdit from "./pages/Documents/document-edit";
import DocumentCreate from "./pages/Documents/document-create";
import DocumentList from "./pages/Documents/document-list";

import UserList from "./pages/User/user-list";
import Theme from "./Theme";

function App() {
  return (
    <>
      <Admin
        theme={Theme}
        dataProvider={dataProvider()}
        authProvider={authProvider}
      >
        <Resource
          name="documento"
          options={{ label: "Documentos" }}
          list={DocumentList}
          create={DocumentCreate}
          edit={DocumentEdit}
        />
        <Resource name="usuario" list={UserList} />
        <Resource name="enumeracoes_admin/status_documento" />
        <Resource name="enumeracoes_admin/tipo_documento" />
        <Resource
          name="macroassuntos"
          // list={MacroassuntoList}
          // create={MacroassuntoCreate}
          // edit={MacroassuntoEdit}
          // show={MacroassuntoShow}
        />
        {/* https://k9jzcca0z7.execute-api.us-east-1.amazonaws.com/dev/macroassuntos/ */}
      </Admin>
    </>
  );
}

export default App;
