import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  useAuthenticated,
  SelectInput,
  ReferenceInput,
  TextInput,
  BooleanInput,
  DateInput,
  AutocompleteArrayInput,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteInput,
} from "react-admin";

import Upload from "../../components/Upload/Upload";
import {
  getUrDocumentlUpload,
  getUrRelatedDocumentlUpload,
} from "../../services/file.service";
import { useEffect } from "react";
import { API } from "aws-amplify";
import config from "../../config/constants";

const choicesTipoModificacao = [
  { id: "ALTERA", name: "Altera" },
  { id: "REVOGA", name: "Revoga" },
  { id: "REGULAMENTA", name: "Regulamenta" },
  { id: "REPUBLICA", name: "Republica" },
  { id: "SUSPENDE", name: "Suspende" },
];

const optionRendererDocument = (choice) =>
  `${choice.tipo_ato} ${choice.numero} / ${choice.ano}`;

const DocumentCreate = (props) => {
  const [choices, setChoices] = useState([]);
  useAuthenticated();

  useEffect(() => {
    API.get(config.APIS.LEGALPHARMA_ADMIN, "macroassuntos").then((res) => {
      setChoices(
        res.data.reduce(
          (acum, current) => [
            ...acum,
            ...current.tags.map((item) => ({
              id: item.entity_id,
              name: `${current.titulo} - ${item.titulo}`,
            })),
          ],
          []
        )
      );
    });
  }, []);

  return (
    <Create {...props}>
      <SimpleForm
        redirect="list"
        defaultValues={{ date: new Date() }}
        // toolbar={<CustomToolbar {...props} resource="blog" />}
      >
        <BooleanInput source="valido" label="Válido" />
        <Upload
          label="Arquivo"
          source="url_do_documento"
          getUrl={getUrDocumentlUpload}
        />
        <ReferenceInput
          label="Status"
          source="status"
          reference="enumeracoes_admin/status_documento"
        >
          <SelectInput optionText="name" fullWidth />
        </ReferenceInput>
        <ReferenceInput
          label="Tipo do ato"
          source="tipo_ato"
          reference="enumeracoes_admin/tipo_documento"
        >
          <SelectInput optionText="name" fullWidth />
        </ReferenceInput>
        <TextInput source="origem" label="Origem" fullWidth />
        <TextInput
          source="observacoes"
          multiline
          label="Observacoes"
          fullWidth
        />
        <DateInput
          source="data_publicacao"
          label="Data de publicação"
          fullWidth
        />
        <TextInput
          source="comentarios"
          multiline
          label="Comentarios"
          fullWidth
        />
        <TextInput source="ementa" multiline label="Ementa" fullWidth />
        <TextInput source="ano" label="Ano" fullWidth />
        <TextInput source="numero" label="Número" fullWidth />
        <ArrayInput source="modificacoes" label="Modificações">
          <SimpleFormIterator>
            <SelectInput
              label="Tipo"
              source="tipo"
              choices={choicesTipoModificacao}
              fullWidth
            />
            <ReferenceInput
              label="Documento"
              source="documento"
              reference="documento"
            >
              <AutocompleteInput
                optionText={optionRendererDocument}
                fullWidth
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <AutocompleteArrayInput
          label="Tags"
          source="tags"
          choices={choices}
          fullWidth
        />
        <ArrayInput
          source="documentos_relacionados"
          label="Documentos Relacionados"
        >
          <SimpleFormIterator>
            <TextInput source="nome" label="Nome" />
            <Upload
              label="Arquivo"
              source="docid"
              getUrl={getUrRelatedDocumentlUpload}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default DocumentCreate;
