import React from "react";
import {
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
  DateField,
  BooleanField,
  useAuthenticated,
} from "react-admin";

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Título" source="titulo" alwaysOn />
  </Filter>
);

// const CustomActions = () => <CardActions>
//     <CreateButton/>
//     <RefreshButton/>
// </CardActions>;

const DocumentList = (props) => {
  useAuthenticated();
  return (
    <List bulkActionButtons={false} {...props} filters={<UserFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="entity_id" label="ID" />
        <TextField source="arquivo" label="Arquivo" />
        <TextField source="tipo_ato" label="Tipo do arquivo" />
        <TextField source="numero" label="Número" />
        <TextField source="ano" label="Ano" />
        <DateField source="data_importacao" label="Data de importação" />
        <DateField source="data_modificacao" label="Data de modificação" />
        <TextField source="origem" label="Origem" />
        <TextField source="status" label="Status" />
        <BooleanField source="valido" label="Válido" />
      </Datagrid>
    </List>
  );
};

export default DocumentList;
